import {handleErrorWithSentry} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import type {HandleClientError} from '@sveltejs/kit';
import {AuthenticationException} from '@blg/api-exception';
import {
	getCurrentRouteName,
	getRoutePath
} from '$lib/utils/navigation/navigation';
import {goto} from '$app/navigation';
import {getListingAndMessage} from '$lib/utils/errors';
import {parseWlkLink} from '$lib/utils/wellKnown';

Sentry.init({
	/* set to empty string to not send errors on Sentry but trigger Sentry on local anyway */
	dsn:
		import.meta.env.MODE === 'production'
			? 'https://a2c7233b312bb06c3c09c5e432f62c11@o4507503212494848.ingest.de.sentry.io/4507583287590992'
			: '',
	tracesSampleRate: 1.0
});

const handleErrorHook: HandleClientError = async (data) => {
	const {message, status, error, event} = data;

	if (import.meta.env.DEV || import.meta.env.TEST) {
		console.error('✖️ handleError', data);
	}

	if (error instanceof AuthenticationException) {
		await goto(getRoutePath('login') || '/login');
		return;
	}

	if (error instanceof Error && is404Error(status, error)) {
		const routeName = getCurrentRouteName(event.url.pathname);

		/* Handle short well kwown. Mandatory here, because isn't a valid route */
		if (event.url.pathname.startsWith('/~')) {
			/* Replace /~ with /wk/ and remove the last slash */
			const wkLink = structuredClone(event.url.pathname)
				.replace('/~', '/wk/')
				.slice(0, -1);
			parseWlkLink(wkLink);
		}

		return {
			status: 404,
			message,
			...getListingAndMessage(routeName)
		};
	}

	return {
		message,
		status
	};
};

function is404Error(status: number, error: App.Error) {
	return status === 404 || error.apiResponse?.statusCode === 404;
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry(handleErrorHook);
